<template>
    <div>
        <div class="rlogo_hd">
            <div class="title">一键去水印</div>
            <div class="form-box">
                <el-input v-model="url" placeholder="请输入URL地址"></el-input>
                <el-button @click="submit">去水印</el-button>
            </div>
        </div>
        <div class="rlogo-body">
            <div class="row">
                <div class="item">视频</div>
                <div class="item">链接</div>
                <div class="item">视频状态</div>
                <div class="item">去水印时间</div>
                <div class="item">操作</div>
            </div>
            <div class="row" v-for="i in items" :key="i">
                <div class="item">
                    <img :src="i.img" alt="" />
                </div>
                <div class="item">
                    {{ i.link }}
                </div>
                <div class="item">
                    <span>{{ i.status }}</span>
                </div>
                <div class="item">
                    {{ i.timer }}
                </div>
                <div class="item">
                    <el-button size="mini">默认按钮</el-button>
                    <el-button type="primary">主要按钮</el-button>
                    <el-button type="success">成功按钮</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { removeLogo } from "@/api/api";
export default {
    name: "VideoNewRemoveLogo",

    data() {
        return {
            url: "",
            items: [
                // {
                // img: require('@/static/images/login/loginbg.png'),
                // link: 'http://www.baidu.com',
                // status: 1,
                // timer: '2022-10-10 13:00',
                // }
            ],
        };
    },

    mounted() {},

    methods: {
        submit() {
            removeLogo({ url: this.url }).then((res) => {
                console.log(res);
            });
        },
    },
};
</script>

<style lang="less" scoped>
.rlogo_hd,
.rlogo-body {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
}
.title {
    margin-bottom: 10px;
}
.form-box {
    display: flex;
    justify-content: space-between;
}
.form-box .el-input {
    flex: 0 0 75%;
}
.form-box .el-button {
    flex: 0 0 20%;
    background-color: #ff8ea0;
    color: #fff;
}
.row {
    display: flex;
}
.item {
    flex: 1;
    display: flex;
    align-items: center;
    img {
        width: 60px;
    }
}
</style>