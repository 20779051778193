import request from '@/utils/request'

// 素材目录增删改查

// 增加库目录
export function addMuen(data) {
    return request({
        url: '/api/app/VideocreationMaterialLibrary/addMateriallibrary',
        // url: '/VideocreationMaterialLibrary/addMateriallibrary',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: {
            ...data
        },
        method: 'POST',
    })
}
// 获取库目录
export function getMuen(params) {
    return request({
        // url: '/VideocreationMaterialLibrary/page',
        url: '/api/app/VideocreationMaterialLibrary/page',
        params,
        method: 'GET',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}
// 获取库目录树结构
export function getMuenTree(data) {
    return request({
        // url: '/VideocreationMaterialLibrary/treeList',
        url: '/api/app/VideocreationMaterialLibrary/treeList',
        params: {
            ...data
        },
        method: 'GET',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

// 删除库目录
export function delMuen(data) {
    return request({
        // url: '/VideocreationMaterialLibrary/' + data,
        url: '/api/app/VideocreationMaterialLibrary/' + data,
        method: 'DELETE',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
        // params: data
    })
}
// 移动库目录
export function removeMuen(mid, tid) {
    return request({
        url: '/api/app/VideocreationMaterialLibrary/movemateriallibrary',
        // url: '/VideocreationMaterialLibrary/movemateriallibrary',
        data: {
            materiallibraryId: mid,
            targetMateriallibraryId: tid
        },
        method: 'POST',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}
// 素材移动
export function moveMaterial(data) {
    return request({
        url: '/api/app/material/movematerial',
        data,
        method: 'POST',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}


// 素材增删改查
// 查询素材
export function getSource(params) {
    return request({
        url: '/api/app/Videocreationfile/page',
        params,
        method: 'GET'
    })
}
// 删除素材
export function delSource(id) {
    return request({
        url: `/api/app/Videocreationfile/${id}`,
        // url: '/api/app/Videocreationfile/page?catalogueSort='+data,
        method: 'post',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}
// 多删除素材
export function delSourceAll(data) {
    return request({
        url: '/api/app/Videocreationfile/batchDelete',
        method: 'post',
        data,
        headers: {
            "Content-Type": "application/json",
        }
    })
}
export function moveSource(materialId, targetMaterialLibraryId) {
    return request({
        url: '/api/app/Videocreationfile/page',
        // url: '/api/app/Videocreationfile/page?catalogueSort='+data,
        data: {
            materialId,
            targetMaterialLibraryId
        },
        method: 'POST',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

export function download(ids) {
    return request({
        url: '/api/app/Videocreationfile/downLoad',
        data: { ids },
        method: 'POST'
    })
}
// 素材重命名
export function resetName(data) {
    return request({
        url: '/api/app/material/reNameMaterial',
        // url: '/api/app/VideocreationMaterialLibrary/reNameMaterialLibrary',
        method: 'POST',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data
    })
}
// 素材库重命名
export function resetMeunName(data) {
    return request({
        url: '/api/app/VideocreationMaterialLibrary/reNameMaterialLibrary',
        method: 'POST',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data
    })
}
export function removeLogo(params) {
    return request({
        url: '/api/app/t_douyin_removewatermark/analyzeVideo',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params
    })
}
export function getCommentList(params) {
    return request({
        url: '/api/app/comment/commentList',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params
    })
}

export function replyComment(data) {
    return request({
        url: '/api/app/comment/replyComment',
        method: 'post',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data
    })
}

export function videoUpdata(data) {
    return request({
        url: '/api/app/Videocreationfile/update',
        method: 'post',
        headers: {
            "Content-Type": "application/json",
        },
        data
    })
}
export function getReplyList(params) {
    console.log(params);
    return request({
        url: '/api/app/comment/commentReplyList',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params
    })
}

